
/************ Custom progress bar ****/
.mdc-progress,
.mdc-progress-base-end {
    height: 2rem !important;
}

.mdc-progress-bar-good,
.mdc-progress-bar-excellent,
.mdc-progress-bar-unrated,
.mdc-progress-bar-regular {
    color: $color-diagnostico !important;
    font-size: 1rem;
    font-weight: 500;
}

.mdc-progress-bar-good {
    background: $good !important;
    width: 80%;
}

.mdc-progress-bar-excellent {
    background: $excellent !important;
    width: 100%;
}

.mdc-progress-bar-unrated {
    background: $unrated !important;
    width: 100%;
}

.mdc-progress-bar-regular {
    background: $regular !important;
    width: 30%;
}

/************ fin de progress bar ****/