html {
    font-size: 14px !important;
}
body {
    padding: 0 !important;
    font-family: $roboto !important;
    width: 100%;
    overflow-x: hidden;
    .content > .container,
    .content > .container > .row {
        height: 100%;
    }

    .content > .container > .row > .col-12 {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }

    .wrapper {
        display: flex;
        width: 100%;
        align-items: stretch;
    }

    .underline {
        text-decoration: underline;
    }
    
    .through{
        text-decoration:line-through;
    }
    
    .mdc-link-detail {
        cursor: pointer;
    }

    .mdc-li-pr {
        border-right: 1px solid $white;
        padding-right: 20px;
    }

    /* Logo */
    .mdc-logo-w,
    .mdc-logo-sesion {
        width: 12vw;
        height: 4vw;
    }

    .mdc-logo-f {
        width: 15vw;
    }

    /**** height ****/
    .h-87 {
        height: 87% !important;
    }

    /**** opacity ****/
    .mdc-opacity-b {
        opacity: 1;
    }

    .mdc-opacity-n {
        opacity: 0.5;
    }

    .mdc-opacity-l {
        opacity: 0.3;
    }

    .bg-secondary {
        position: relative;
        background: $primary-color !important;
    }
    

    /* HR */

    .mdc-hr {
        background-color: $primary-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0;
    }
    .mdc-hr-white {
        background-color: $white;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0;
    }
    .mdc-hr-bc {
        background-color: $buscacredito-color;
        width: 20%;
        max-width: 200px;
        height: 3px;
        border-radius: 10px;
        margin: 0;
    }

    //* Button chat override */
    #idChatDatacredito > a {
        color: $white !important;
    }

    /******************* text-colors ********************/
    .mdc-text-primary {
        color: $gray-primary !important;
    }

    .mdc-text-secondary {
        color: $secondary-color !important;
    }

    .mdc-text-secondary-dark {
        color: $primary-color !important;
    }

    /* Navbars */

    .tab-pane {
        color: $gray-link;
    }

    /***** mdband *****/
    .mdc-band {
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;
        color: $gray-primary;
    }

    .mdc-band-none {
        background: none;
        opacity: 1;
    }

    .mdc-band-h1 {
        height: auto;
    }

    /*****  fin mdband *****/

    .bg-pay {
        position: relative;
        padding-top: 110px !important;
        padding-bottom: 70px;
        background-image: url(../../../assets/img/backgrounds/bkground2.svg);
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-color: $bg;
        min-height: 800px;
        height: auto;
        z-index: 0;
        margin-top: -40px;
    }

    .bg-servicio {
        position: relative;
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        min-height: 100%;
        z-index: 0;
    }

    .bg-private {
        position: relative;
        background-blend-mode: multiply;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        z-index: 0;
        min-height: auto;
    }

    /***** utils ****/
    .mt-arrow {
        margin-top: 0.30rem;
    }

    .mt-6 {
        margin-top: 4rem;
    }

    .mt-7 {
        margin-top: 5rem;
    }

    .mt-8 {
        margin-top: 6rem;
    }

    .mt-9 {
        margin-top: 6.4rem;
    }

    .mb-6 {
        margin-bottom: 4rem;
    }

    .mb-8 {
        margin-bottom: 6rem;
    }

    .mb-7 {
        margin-bottom: 5rem;
    }

    .mdc-mt-n {
        margin-top: -30px !important;
    }

    .py-6 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.2rem !important;
    }

    /***** line heigh ****/
    .mdc-lh-1 {
        line-height: 0.25;
    }

    .mdc-lh-2 {
        line-height: 0.5;
    }

    .mdc-lh-3 {
        line-height: 0.75;
    }

    .mdc-lh-4 {
        line-height: 1.0;
    }

    .mdc-lh-5 {
        line-height: 1.5 !important;
    }


    .mdc-list-pay li:nth-child(1)::after {
        content: "";
        position: absolute;
        top: 20%;
        left: 22%;
        width: 28.0%;
        border-top: 1px solid $primary-color;
    }

    .mdc-list-pay li:nth-child(2)::after {
        content: "";
        position: absolute;
        top: 20%;
        left: 50%;
        width: 28.3%;
        border-top: 1px solid $primary-color;
    }

    .mdc-list-pay {
        text-align: center;
        padding-bottom: 50px;
    }

    .mdc-list-pay li {
        display: table-cell;
        color: $white;
        width: 8.16%;
    }

    .mdc-list-pay li img {
        position: relative;
        z-index: 2;
        margin: 16px auto;
    }

    .mcd-lt-info-pay li {
        position: relative;
        font-size: 0.875rem;
        margin-top: 15px;
        list-style: none;
    }

    .mcd-lt-info-pay li::after {
        position: absolute;
        top: 5px;
        left: -40px;
        content: ' ';
        background-size: 28px 28px;
        height: 28px;
        width: 28px;
    }

    /***** listas *****/
    .mdc-nav-reclamos li {
        position: relative;
        display: inline;
        margin: 60px;
    }

    .mdc-nav-reclamos li a{
        margin-top: 15px;
        font-size: 0.75rem;
        text-align: center;
        color: $gray-secondary !important;
    }
    .mdc-nav-reclamos li.active a {
        margin-top: 15px;
        font-size: 0.75rem;
        text-align: center;
        color: $primary-color ;
    }

    .mdc-nav-reclamos li.active a.active {
        margin-top: 15px;
        font-size: 0.75rem;
        text-align: center;
        color: $primary-color !important;
        font-weight: bolder;
    }

    .mdc-nav-reclamos li:nth-child(1)::after {
        content: "";
        position: absolute;
        border-top: 1px solid $primary-color;
        ;
        top: -10px;
        left: 48px;
        width: 380px;
    }

    .mdc-nav-reclamos li::before {
        content: "";
        position: absolute;
        top: -15px;
        left: 40%;
        width: 10px;
        height: 10px;
        border: 1px solid $black;
        border-radius: 50%;
        background: $dark-white;
    }

    .mdc-nav-reclamos li.active::before {
        background: $primary-color;
    }

    .mdc-ul-info li:nth-child(odd) {
        color: $primary-color;
        font-size: 1.875rem;
        font-weight: bold;
        margin-bottom: 25px;
    }

    .mdc-ul-info li:nth-child(even) {
        color: $gray-primary;
        list-style: none;
        font-size: 1.25rem;
        margin-bottom: 20px;
    }

    .mdc-ul-items li {
        list-style: none;
        margin-top: 15px;
        color: $gray-link;
    }

    .mdc-ul-items li::before {
        content: "\2022";
        color: $primary-color;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        /*  margin-right: 15px; */
    }

    .mdc-ul-credit li {
        list-style: none;
    }

    .mdc-list-questions > li {
        list-style: none;
        margin: 10px 0;
        color: $gray-primary;
        font-weight: lighter;
    }

    .mdc-list-questions > li::before {
        content: "> ";
        position: relative;
        color: $primary-color;
        height: 10px;
        width: 10px;
        font-weight: bold;
        margin-left: -13px;
    }


    .mdc-list-cards {
        padding: 0;
        margin-top: 15px;
    }

    .mdc-list-cards li {
        list-style-type: none;
        display: inline-block;
    }

    .mdc-list-cards li button {
        background: transparent;
        border: none;
        padding: 0;
    }

    .mdc-list-cards li button img {
        width: 70px;
        height: 65px;
    }

    /***** fin de listas *****/

    /** Dropdown **/
    .mdc-drop button {
        background: $secondary-color;
        width: 100%;
        text-align: left;
        border-radius: 5px;
    }

    .mdc-drop button::after {
        display: none;
    }

    .mdc-drop button:not(:disabled):not(.disabled):active,
    .mdc-drop button:hover,
    .mdc-drop button:focus,
    .mdc-drop.show > button.dropdown-toggle {
        background: $secondary-color !important;
        border-color: $secondary-color !important;
        box-shadow: none !important;
    }

    .mdc-drop > .dropdown-menu {
        background: $secondary-color;
        margin-top: -5px;
        border: none;
        padding: 0;
    }

    .mdc-drop > .dropdown-menu a {
        color: #fff;
        padding: 16px .75rem;
        border-bottom: 1px solid#342439;
    }

    .mdc-drop > .dropdown-menu .dropdown-item:focus,
    .mdc-drop > .dropdown-menu .dropdown-item:hover {
        background: $secondary-color;
    }

    .mdc-drop > .dropdown-menu a img,
    .mdc-drop button img {
        height: 20px;
        width: 30px;
    }

    @media (max-width: 991.98px) {
        /*** list ***/
        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 16%;
            left: 20.9%;
            width: 25.2%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 16%;
            left: 53.2%;
            width: 25.3%;
            border-top: 1px solid $primary-color;
        }
    }

    @media (max-width: 767.98px) {
        /*** logos ***/
        .mdc-logo-w,
        .mdc-logo {
            width: 20vw;
            height: 7vw;
        }
        .mdc-li-pr {
            padding: 0;
            border: none;
        }
        .mdc-band {
            background: $gray-link;
            color: $white;
            line-height: 1.3em;
        }
        .mdc-hr-white {
            margin: 0 auto;
        }
        .content {
            margin-left: 0px;
            min-height: auto;
            padding-top: 59px;
        }
        
        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 34%;
            left: 20.3%;
            width: 24%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 34%;
            left: 53.2%;
            width: 23.8%;
            border-top: 1px solid $primary-color;
        }

    }

    @media (max-width: 576px) {
        /*** logos ***/
        .mdc-logo-sesion {
            width: 120px;
            margin-left: 0px !important;
        }

        .mdc-logo-w {
            width: 35vw;
            height: 12vw;
        }

        .bg-pay {
            padding-top: 80px !important;
            padding-bottom: 20px;
        }
        
        .bg-steps {
            height: auto;
        }
        
        .mdc-list-pay {
            padding-bottom: 10px;
        }

        .left.dotted-spaced,
        .top.dotted-spaced {
            background-image: radial-gradient(circle at center, $secondary-color 0, $dark-white, $dark-white);
            background-position: top;
            background-size: 6px 3px;
            background-repeat: repeat-x;
            margin-top: 40px;
            padding-top: 40px;
        }

        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 16%;
            left: 21.3%;
            width: 22.2%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 16%;
            left: 52.8%;
            width: 24.1%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li {
            font-size: 12px;
        }

        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 25%;
            width: 23%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 54%;
            width: 22.1%;
            border-top: 1px solid $primary-color;
        }
        
        .mdc-list-deuda li {
            font-size: 10px;
            margin: 5px 0;
            line-height: 1.2;
        }
        .mdc-list-cards li button img {
            width: 60px;
        }

        .mdc-ul-info li:nth-child(odd) {
            font-size: 1.3rem;
            margin-top: 15px;
        }

        .mdc-ul-info li:nth-child(even) {
            font-size: 1.0rem;
            margin-top: 15px;
        }
    }

    @media (max-width: 425px) {
        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 23%;
            width: 21.8%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 52%;
            width: 20.5%;
            border-top: 1px solid $primary-color;
        }
    }
    @media (min-width: 320px) and (max-width: 375.98px) {
    
        .mdc-logo-w {
            width: 35vw;
            height: 12vw;
        }

        /*** paddings ***/
        .p-xs-0 {
            padding-left: 2px !important;
            padding-right: 2px !important;
        }
        
        .mdc-list-pay li:nth-child(1)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 25%;
            width: 19%;
            border-top: 1px solid $primary-color;
        }

        .mdc-list-pay li:nth-child(2)::after {
            content: "";
            position: absolute;
            top: 27%;
            left: 53%;
            width: 20%;
            border-top: 1px solid $primary-color;
        }
    }
    @media (max-width: 320px) {
        /*** logos ***/
        .mdc-logo-w {
            margin-left: 0px !important;
        }
    }
}