body {
    .mdc-block,
    .mdc-block-bold,
    .mdc-block-dark {
        background: rgba(100, 74, 184, 0.5);
        border-radius: 10px;
        height: auto;
        vertical-align: middle;
        padding-top: 9px;
    }
    
    .mdc-block-dark {
        background: $secondary-color;
    }

    .mdc-block-bold {
        background: $primary-color;
    }

    .mdc-block-l {
        background: #CECBD3;
    }

    .mdc-block-shadow {
        background-color: $dark-white;
        box-shadow: $third-color 2px 5px 12px;
    }

    .mdc-block-principal,
    .mdc-block-secondary {
        background: #e8e8e8;
        border-radius: 10px;
        height: auto;
        vertical-align: middle;
        padding-top: 9px;
    }

    .mdc-block-secondary {
        background: $primary-color;
    }

    .mdc-block-info-position {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    .mdc-block-info {
        background: $primary-color;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        height: auto;
        vertical-align: middle;
        padding: 25px;
        color: #fff;
        font-size: 1.25rem;
        font-weight: lighter;
    }

    .mdc-block-info-position-chat {
        width: 50%;
        max-width: 400px;
        position: fixed;
        bottom: 0;
        right: 2%;
        z-index: 5;
    }

    .mdc-block-info img {
        height: 50px;
        margin-top: 5px;
    }

    .mdc-block-default {
        background: rgba(118, 117, 121, 0.15);
        height: auto;
    }

    .mdc-block-default-dark {
        background: rgba(118, 117, 121, 0.5);
        height: auto;
    }
    .mdc-block-white-dark {
        background: rgba(255, 255, 255, 0.5);
        height: auto;
    }

    .mdc-block-transparent {
        background: transparent;
        border-radius: 15px;
        height: auto;
        vertical-align: middle;
        padding-top: 9px;
    }
    /* Medium devices (tablets, 768px and up) md */
    /********************************************************************************************************************************************************************/
        @media (max-width: 767.98px) {
        /*** bloques ***/
        .mdc-block-info-position-chat {
            position: fixed;
            bottom: 0;
            right: 2%;
            font-size: 12px;
            z-index: 5;
        }

        .mdc-block-info-position-chat .col-8 {
            font-size: 12px;
        }

        .mdc-block-info-position-chat .col-4 img {
            width: 30px !important;
            margin-left: 16px;
            margin-top: -5px;
            max-width: 40px !important;
        }
    }
    /* Small devices (landscape phones, 576px and up) sm */
    /********************************************************************************************************************************************************************/
    @media (max-width: 576px) {
         /** bloques **/
        div.mdc-block.mdc-gift {
            background: transparent;
        }

        .mdc-block-info-position-chat {
            width: 30% !important;
            position: fixed;
            bottom: 0;
            right: 2%;
            font-size: 12px;
            z-index: 5;
        }

        .mdc-block-info-position-chat .col-8 {
            font-size: 12px;
        }

        .mdc-block-info-position-chat .col-4 img {
            width: 30px !important;
            margin-left: 16px;
            margin-top: -5px;
            max-width: 40px !important;
        }

        .mdc-block-info {
            padding: 10px;
        }

        .mdc-block-info {
            width: 50% !important;
            height: 65px !important;
        }

        .mdc-block-info img {
            width: 30px;
        }

        .mdc-band-h1 {
            height: auto;
        }
        .mdc-block-info-position-chat {
            width: 50% !important;
            position: fixed;
            bottom: 0;
            right: 2%;
            font-size: 12px;
            z-index: 5;
        }
    
        .mdc-block-info-position-chat .col-8 {
            font-size: 12px;
        }
    
        .mdc-block-info-position-chat .col-4 img {
            width: 30px !important;
            margin-left: 16px;
            margin-top: -5px;
            max-width: 40px !important;
        }
    }

}