body {
    .bg-dark {
        background-color: $gray-secondary !important;
    }
    .dropdown-item {
        font-size: 0.9rem !important;
    }
    .dropdown-toggle::after {
        display: none;
    }
    .table {
        margin-bottom: 0rem;
    }
    .border-white {
        border: 1px solid #ffffff !important;
    }
    .progress-bar {
        border-bottom-right-radius: 25px !important;
        border-top-right-radius: 25px !important;
    }
    .invalid-feedback.small {
        font-size: 0.5em !important;
    }
    .table thead th {
        border-bottom:  0 !important;
    }
    .table th {
        border-top:  0 !important;
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: #e9ecef !important;
        opacity: 0.8 !important;
    }.invalid-feedback {
        font-size: 90% !important;
    }
    .modal-lg, .modal-xl {
        max-width: 900px !important;
    }
    .page-link {
        background-color: transparent !important;
    }
}