/******* borders *******/
body {

    .border-secondary {
        border-color: $primary-color !important;
    }

    .mdc-bn {
        border: none;
    }

    .mdc-bt-none {
        border-bottom: none !important;
    }

    .mdc-bt-1 {
        border-bottom: 1px solid $primary-color;
    }

    .mdc-bt-l {
        border-bottom: 1px solid $secondary-color;
    }

    .mdc-brl-white {
        border-left: 1px solid $white;
    }

    .mdc-brr-white {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }

    .mdc-brr-purple {
        border-right: 1px solid $primary-color;
    }

    .mdc-br {
        border-radius: 15px;
    }

    .mdc-br-1 {
        border-radius: 10px;
    }

    .mdc-br-l-1 {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .mdc-br-r-1 {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .left.dotted-spaced,
    .left.dotted-spaced-white {
        background-position: left;
        background-size: 3px 8px;
        background-repeat: repeat-y;
    }

    .left.dotted-spaced {
        background-image: radial-gradient(circle at center, $secondary-color 0, $dark-white, $dark-white);
    }

    .left.dotted-spaced-white {
        background-image: radial-gradient(circle at center, $secondary-color 0, $white, $white);
    }

    .left {
        float: left;
    }

    .mdc-md-top.dotted-spaced {
        background-image: radial-gradient(circle at center, $secondary-color 0, $dark-white, $dark-white);
        background-position: top;
        background-size: 6px 3px;
        background-repeat: repeat-x;
        margin-top: 40px;
        padding-top: 40px;
    }

    .mdc-br-1 {
        border-radius: 10px;
    }

    /******* fin borders *******/

    @media (max-width: 991.98px) {
        .mdc-br-purple {
            border: none;
        }
    }
    @media(max-width: 768px) {
        .mdc-brr-purple {
            border-right: none;
        }
    }
    @media (max-width: 576px) {
        .mdc-br-purple {
            border-right: 1px solid $primary-color;
        }
        .border-right {
            border-right: none !important;
        }
        .mdc-brl-white {
            border-left: none;
        }
        .mdc-bl-default {
            border-left: 4px solid $gray-secondary;
        }

        .mdc-br-default {
            border-right: 4px solid $gray-secondary;
        }
    }
}