body {
    form {
        .mat-form-field-flex {
            background: $white;
        }
            
        select {
            -webkit-appearance: none;
            appearance: none;
            -moz-appearance: none;
            -ms-progress-appearance: none;
            -o-appearance: none;
            background-color: $white !important;
            padding-left: 0.5em !important;
        }
        select + i {
            float: right;
            pointer-events: none;
            margin-top: -30px;
            margin-right: 15px;
        }
    }

    .form-group .form-control-icon {
        position: absolute;
        right: 4.5%;
        top: 8px;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        color: $gray-secondary;
    }

    /*** select ***/
    .mdc-v-scroll {
        height: 500px;
        overflow-y: scroll;
        background: $dark-white;
        color: $gray-link;
    }

    .mdc-v-scroll .col-1,
    .mdc-v-scroll .col-11 {
        border-bottom: 1px solid $dark-white;
    }

    .mdc-arrow-down {
        position: absolute;
        top: 8px;
        right: 25px;
        z-index: 6;
        width: 12px;
    }

    select {
        -moz-appearance: window;
        -webkit-appearance: none;
        padding-right: 20px;
        font-size: 14px !important;
    }

    select::-ms-expand {
        display: none;
    }

    /*********************custom arrow select and checkbox***************************/
    /*** input ***/
    .mdc-form-control:disabled {
        background: #a6a6a6;
        color: #808080;
    }

    /*** button **/
    .mdc-btn-form-control:disabled {
        background: #a6a6a6;
        color: #808080;
        opacity: 1;
    }

    /*** fin de select ***/
    /*** checkbox ***/
    .checkbox label:after {
        content: '';
        display: table;
        clear: both;
    }

    .checkbox .cr {
        position: relative;
        display: inline-block;
        border: 1px solid #a9a9a9;
        border-radius: .25em;
        width: 1.3em;
        height: 1.5em;
        float: left;
        margin-right: .5em;
    }

    .checkbox .cr .cr-icon {
        position: absolute;
        font-size: .8em;
        line-height: 0;
        top: 50%;
        left: 15%;
    }

    .checkbox label input[type="checkbox"] {
        display: none;
    }

    .checkbox label input[type="checkbox"] + .cr > .cr-icon {
        opacity: 0;
    }

    .checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
        opacity: 1;
    }

    .checkbox label input[type="checkbox"]:disabled + .cr {
        opacity: .5;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #adb5bd;
        background-color: #ffffff;
    }

    .mdc-checkbox-big:before {
        height: 1.4rem !important;
        width: 1.4rem !important;
        background: transparent;
        margin-top: 8px;
    }

    .mdc-checkbox-big:after {
        width: 1.4rem !important;
        height: 1.4rem !important;
        margin-top: 8px;
    }

    /** fin de checkbox ****/
    /**** Errores en formularios *****/
    .field-error .error-icon {
        margin-top: 5px;
        background-position: top;
        background-repeat: no-repeat;
        height: 30px;
        margin-left: -8px;
        position: absolute;
        top: 5%;
        width: 30px;
        right: -20px;
    }

    .field-error input {
        border: 2px;
        border-color: #ba2f7d;
        border-style: solid;
    }

    .field-error select {
        border: 2px;
        border-color: #ba2f7d;
        border-style: solid;
    }


    @media (max-width: 767.98px) {
        .mdc-arrow-down {
            position: absolute;
            top: 8px;
            z-index: 0;
        }
    }

    @media (max-width: 576px) {
        .form-group .form-control-icon {
            width: 1rem;
            height: 1rem;
            top: 12px;
            right: 7%;
        }
    }
}