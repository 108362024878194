body {
    /****** icons ********/
    .mdc-icon-asesor {
        width: 70px;
    }

    .mdc-icon {
        width: 250px;
        height: 150px;
    }

    .mdc-icon-big {
        height: 250px;
    }

    .mdc-icon-list {
        height: 3em;
    }

    .mdc-lg-icon {
        width: 100px;
    }

    .mdc-mini-icon {
        width: 80px;
    }

    .mdc-md-icon,
    .mdc-big-icon {
        height: 120px;
    }

    .mdc-small-icon {
        height: 3em;
    }

    .mdc-small-service {
        height: 2.4em;
    }

    .mdc-sm-icon {
        height: 1.2em;
        &.filter {
            filter: invert(46%) sepia(65%) saturate(2894%) hue-rotate(283deg) brightness(400%) contrast(100%);
        }
    }

    .mdc-xs-icon {
        height: 15px;
    }

    .mdc-icon-xs {
        width: 25px;
    }

    .mdc-icon-alert,
    .mdc-base-icon {
        height: 70px;
    }

    .mdc-down-icon {
        height: 5px;
    }

    .mdc-icon-deuda {
        height: 45px;
    }

    .tooltip-inner {
        background: $primary-color;
        color: $white;
    }

    .bs-tooltip-auto[x-placement^=bottom] .arrow::before,
    .bs-tooltip-bottom .arrow::before {
        border-bottom-color: $primary-color;
    }

    .bs-tooltip-auto[x-placement^=top] .arrow::before,
    .bs-tooltip-top .arrow::before {
        border-top-color: $primary-color;
    }

    .mdc-help {
        position: absolute;
        top: 15%;
        right: 8%;
        width: 20px;
        height: 20px;
    }

    .mdc-nav-icon-help {
        height: 13px;
        padding-left: 10px;
        position: absolute;
        margin-top: 6px;
    }

    .mdc-vertical-icon {
        position: absolute;
        top: -25px;
        right: 85px;
    }

    .mdc-icon-exp {
        right: 85px;
    }

    .mdc-icon-portafoleo {
        right: 65px;
        top: -27px;
    }

    .mdc-icon-habito {
        right: 110px;
        top: -27px;
    }

    .mdc-icon-menu-arrow-user {
        font-size: 15px;
    }

    .mdc-icon-menu-user {
        width: 20px;
    }

    .mdc-icon-menu-campana {
        width: 25px;
    }

    .mdc-nav-right-side {
        width: 38% !important;
    }

    .mdc-icon-menu-ham {
        width: 20px;
    }

    .mdc-big-icon {
        height: 90px;
    }

    .mdc-md-icon {
        height: 80px;
    }
    /*** fin de icons ***/

    @media (max-width: 991.98px) {
        .mdc-big-icon {
            height: 90px;
        }

        .mdc-md-icon {
            height: 80px;
        }

        .mdc-card .mdc-diag-footer {
            left: 2.8%;
        }

    }

    @media (max-width: 767.98px) {
        .mdc-mini-icon {
            width: 65px;
        }
    }

    @media (max-width: 576px) {
        .mdc-big-icon {
            height: 50px;
        }

        .mdc-icon-deuda {
            height: 35px;
        }
    }
}