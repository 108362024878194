    body {
    /***** custom tabs ****/
    .mdc-tabs {
        color: #fff;
        max-width: 75%;
        margin: 0 auto;
    }

    .mdc-tabs .nav-link.active {
        background: $secondary-color;
        color: $white;
    }

    .mdc-tabs .nav-link {
        background: $white;
        color: $secondary-color;
        border: 1px solid $secondary-color;
        padding: 10px 0;
        font-size: 1rem;
    }

    .mdc-tabs li:first-child .nav-link {
        border-top-left-radius: 40px !important;
        border-bottom-left-radius: 40px !important;
        border-right: 2px solid #dddddd;
    }

    .mdc-tabs li:last-child .nav-link {
        border-top-right-radius: 40px !important;
        border-bottom-right-radius: 40px !important;
        border-left: 2px solid #dddddd;
    }

    /*** fin de custom tabs ***/
}