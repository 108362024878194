body {
    /*** cards ***/
    .mdc-card-extra > .mdc-scroll-bar {
        overflow-y: scroll;
        overflow-x: hidden;
        height: 230px;
    }

    /****** Custom Card*******/
    .mdc-card .mdc-card-header {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .mdc-card .mdc-card-header img {
        transform: rotate(90deg);
    }

    .mdc-card .mdc-card-side-left {
        background: $primary-color;
        color: $white;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .mdc-card .mdc-card-side-right {
        background: $dark-white;
        border-bottom-right-radius: 15px;
    }

    .mdc-card .mdc-card-side-right h6 {
        display: list-item;
        color: $gray-primary;
    }

    .mdc-card .mdc-card-side-left .rotate {
        transform: rotate(-90deg);
    }

    .mdc-card .mdc-card-side-left .rotate img {
        transform: rotate(90deg);
    }

    .mdc-card .mdc-diag-footer {
        position: absolute;
        bottom: 0;
        left: 15px;
        text-align: center;
        width: 100%;
        border-bottom-right-radius: 15px;
        background: $third-color;
    }

    .mdc-card .mdc-card-extra {
        background: $dark-white;
        height: 250px;
        overflow-y: hidden;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }

    .mdc-card .mdc-card-hidden {
        display: none;
    }

    .mdc-diag-left {
        transform: rotate(-90deg);
        position: absolute;
        width: 250px;
        top: 95px;
        left: -135px;
        height: 60px;
        text-align: center;
        background: $primary-color;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        color: $white;
    }

    .mdc-diag-footer {
        position: absolute;
        bottom: 0;
        left: 1.5%;
        text-align: center;
        width: 100%;
    }

    .mdc-diag-out {
        position: absolute;
        bottom: 0;
        text-align: center;
        width: 100%;
    }

    .mdc-card-default {
        background-color: transparent;
        height: 410px;
        border-radius: 5px;
        border: 1px solid $dark-white;
    }

    .md-card-info {
        background: $secondary-color;
        height: 410px;
        border-radius: 5px;
        border: 1px solid $secondary-color;
    }

    .mdc-bottom {
        position: absolute;
        bottom: 5%;
        left: 8%;
        width: 85%;
    }

    /*** fin de cards ***/
    
    @media (min-width: 991.98px) and (max-width: 1200px){
        /*** cards ***/
         .md-card-info {
        height: 500px;
        }
    }
    
    
    @media (max-width: 991.98px) {
        /*** cards ***/
        .mdc-card .mdc-diag-footer {
            left: 2.7%;
        }
    }
    
    @media (min-width: 768px) and (max-width: 991.98px) {
        /*** cards ***/
        .pricing-card-title {
            font-size: 1.3em;
        }
        .md-nav-info li {
            font-size: 0.7em;
        }
    }

    @media (max-width: 576px) {
        .mdc-card-default {
            height: 365px;
        }

        .md-card-info {
            height: 425px;
        }

        .mdc-card .mdc-diag-footer {
            left: 2.7%;
        }
    }
}