
.mobilePlans {

    .fixedTipo {
        position: fixed;
        top: 55px;
        z-index: 100;
        width: 100%;
        margin: 0 auto;
        display: flex;
        left: 0;
        background: #f6f6f7 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 5px #0000004D;
        height: 55px !important;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        div {
            align-self: center !important;
            -webkit-box-pack: center !important;
            justify-content: center !important;
        }
    }
}