.chatbot {
  position: fixed;
  min-width:  240px;
  width: 15%;
  bottom: 0;
  right: 5%;
  background: #972780;
  color: white;
  border: none;
  border-radius: 5px 5px 0 0;
  z-index:  1;
  cursor: pointer;
}

.icon-square img {
  width: 60%;
  margin: 20%;
}

.text-square p {
  font-size: 15px;
  margin: auto;
  padding: 12% 10% 7% 0;
  font-family: $din;
  line-height: 17px;
}

.icon-square {
  width: 33.3%;
  float: left;
  margin-top: 3px;
}

.text-square {
  width: 66.7%;
  float: left;
}

.icon-square:after{
  content: '';
  border-left: 2px solid #c68bba;
  height: 40px;
  width: 5px;
  top: 10px;
  position: absolute;
}

.chat-bot {
  position: fixed;
  z-index: 1000;
  height: auto;
  bottom: 0%;
  right: 2%;
}

.mCSB_container {
  overflow: auto;
  width: auto;
  max-height: 380px;
  overflow-x: hidden;
}

.chatb{
  font-family:  $din;
  width: 310px;
  height: 80vh;
  max-height: 500px;
  z-index: 2;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  background: #ffffff;
  border-radius: 15px 15px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.chat-title {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 45px;
  flex: 0 1 45px;
  position: relative;
  z-index: 2;
  background: #972780;
  color: #fff;
  text-align: left;
  padding: 15px 25px;
  font-family:  $din;
  font-size: 16px;
}

.chat-title h1, .chat-title h2 {
  font-weight: normal;
  font-size: 10px;
  margin: 0;
  padding: 0;
}

.chat-title h2 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 8px;
  letter-spacing: 1px;
}

.messages {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  color: #706F6F;
  overflow: hidden;
  position: relative;
  width: 100%;
  border: 3px solid #972780;
  border-bottom:  0px;
  border-top:  0px;
}

.messages .messages-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 101%;
  width: 100%;
}

.messages .message {
  max-width: 250px;
  clear: both;
  float: left;
  border-radius: 5px 5px 5px 0;
  background: #F6F6F6;
  margin: 8px 0;
  font-size: 16px;
  line-height: 1.4;
  margin-left: 10px;
  position: relative;
  padding:  10px;
  color: #706F6F;
}

.messages .message .timestamp {
  position: absolute;
  bottom: -15px;
  font-size: 9px;
  color: #706F6F;
}

.messages .message::before {
  content: '';
  position: absolute;
  bottom: 0px;
  border-bottom: 6px solid #f6f6f6;
  left: -6px;
  border-left: 7px solid transparent;
}

.messages .message.message-personal {
  float: right;
  color: #fff;
  text-align: right;
  background: $primary-color;
  border-radius: 5px 5px 0 5px;
  margin-right:  6px;
}

.messages .message.message-personal::before {
  left: auto;
  right: -4px;
  border-right: 5px solid transparent;
  border-bottom: 4px solid $primary-color;
  bottom: 0;
}

.messages .message:last-child {
  margin-bottom: 30px;
  margin-left: 6px;
}

.messages .message.new {
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-animation: bounce 500ms linear both;
          animation: bounce 500ms linear both;
  text-align: left;
}

.messages .message.loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
          animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  border: none;
  -webkit-animation-delay: .15s;
          animation-delay: .15s;
}

.messages .message.loading span {
  display: block;
  font-size: 0;
  width: 20px;
  height: 10px;
  position: relative;
}

.messages .message.loading span::before {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
          animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: -7px;
}

.messages .message.loading span::after {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  content: '';
  display: block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  margin-top: 4px;
  -webkit-animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
          animation: ball 0.45s cubic-bezier(0, 0, 0.15, 1) alternate infinite;
  margin-left: 7px;
  -webkit-animation-delay: .3s;
          animation-delay: .3s;
}

.message-box {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 40px;
          flex: 0 1 40px;
  width: 100%;
  background: $primary-color;
  padding: 10px;
  position: relative;
  text-align: left;
}

.message-box .message-input {
  border-radius: 3px;
  outline: none !important;
  border: none;
  resize: none;
  color: #706F6F;
  font-size: 14px;
  margin: 0;
  padding: 10px;
  width: 79%;
  background-color: white;
}

.message-box input::-webkit-input-placeholder {
  color: #B2B2B2;
}
.message-box input:-ms-input-placeholder {
  color: #B2B2B2;
}
.message-box input::-ms-input-placeholder {
  color: #B2B2B2;
}
.message-box input::placeholder {
  color: #B2B2B2;
}
.message-box input:focus:-webkit-placeholder {
  color: #fff;
}
.message-box .message-submit {
  position: absolute;
  font-family:  $din;
  z-index: 1;
  top: 14px;
  right: 10px;
  color: #fff;
  border: none;
  background: $primary-color;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  padding: 10px 10px;
  outline: none !important;
  -webkit-transition: background .2s ease;
  transition: background .2s ease;
}
.message-box .message-submit:hover {
  background: $primary-color;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@keyframes bounce {
  0% {
    -webkit-transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    -webkit-transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    -webkit-transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    -webkit-transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    -webkit-transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    -webkit-transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    -webkit-transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    -webkit-transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    -webkit-transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    -webkit-transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
            transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
@-webkit-keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
            transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}
@keyframes ball {
  from {
    -webkit-transform: translateY(0) scaleY(0.8);
            transform: translateY(0) scaleY(0.8);
  }
  to {
    -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
  }
}


.chatt {
  width: 300px;
  height: 82vh;
  max-height: 440px;
  min-height: 420px;
  z-index: 2;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  background: #972780;
  border-radius: 15px 15px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.chatt>.row>.div {
  text-align:  center;
  padding-bottom:  5%;
  padding-top:  5%;
  color:  #fff;
  font-size: 20px;
  cursor:  pointer;
  font-family:  $din;
}

.chatt>.row>.div>img {
  width: 10%;
  margin-right: 3%;
}

.titulo-form {
  text-align:  center;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  color: $primary-color;
  background: #F0F0F0;
  border-radius: 20px 20px 0 0;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: bold;
}

.titulo-form p {margin:  0;}
.cuerpo-form {
  text-align: center;
  margin-left: 10%;
  margin-right: 10%;
  width: 80%;
  color: $primary-color;
  background: #FFFFFF;
  border-radius: 0 0 20px 20px;
  font-size: 18px;
  padding-top: 0px;
  padding-bottom: 7px;
  padding-left:  5%;
  padding-right:  5%;
}

.cuerpo-form input[type="text"] {
  width:  100%;
  font-size: 12px;
  border-radius: 8px;
  text-align:  center;
  padding: 10px 10px 5px 10px;
  margin-bottom:  11px;
  border: 1px solid #C2C2C2;
  color: #5D5D5D;
  opacity:  0.7;
}

.texto-form {
  font-size: 14px;
  color: #5D5D5D;
  padding: 30px 0 30px 0;
  line-height: 20px;
  font-weight:  bold;
}

.iniciar-chat {
  width: 180px;
  background: $primary-color;
  color:  #ffffff;
  padding: 7px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 20px;
  font-size: 14px;
  box-shadow: none !important;
  border: none;
}

img.chat-img1 {
  width:  30px;
}
img.chat-img2 {
  width: 120px;
  margin-left: 5px;
  margin-top:  -4px;
}
.div-form {
  font-family:  $din;
}
.message {
  word-wrap: break-word;
}