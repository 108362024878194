@import './../../../assets/scss/abstracts/variables';

body {
    .mdc-text-family {
        font-family: $din !important;
    }

    .mdc-line-height {
        line-height: 1;
    }

    .mdc-text-b {
        font-weight: bold;
    }

    .mdc-text-l {
        font-weight:200;
    }

    .mdc-text-sb {
        font-weight:300;
    }

    .mdc-text-sb2 {
        font-weight:400;
    }

    .mdc-text-sb3 {
        font-weight:800;
    }

    .mdc-text-n {
        font-weight: normal;
    }

    .mdc-text-transparent {
        color: transparent;
    }

    /******************* text-colors ********************/
    .mdc-text-primary {
        color: $gray-primary !important;
    }

    .mdc-text-secondary {
        color: $secondary-color !important;
    }

    .mdc-text-secondary-dark {
        color: $primary-color !important;
    }

    .mdc-text-info-lighter {
        color: $secondary-color;
    }

    .md-text-default {
        color: $gray-secondary;
    }

    .mdc-text-gray {
        color:$gray-link;
    }

    .mdc-text-pink {
        color: $pink;
    }

    .mdc-text-dark {
        color: $gray-dark;
    }

    .mdc-text-service {
        color: $gray-primary;
    }

    .mdc-text-white {
        color: $white;
    }

    .mdc-text-bc {
        color: $buscacredito-color !important;
    }

    /**** text utils ****/
    .mdc-text-u {
        text-decoration: underline;
    }

    /****** text-sizes*******/
    .mdc-text {
        font-size: 6.0625rem;
    }

    .mdc-text-1 {
        font-size: 4.0625rem;
    }

    .mdc-text-2-5 {
        font-size: 3.75rem;
    }

    .mdc-text-2 {
        font-size: 3.125rem;
    }

    .mdc-text-2-5-1 {
        font-size: 2.125rem;
    }

    .mdc-text-2-5-2 {
        font-size: 2.625rem;
    }

    .mdc-text-3-5-1 {
        font-size: 30px;
        line-height: 30px!important;
        font-weight: 500;
    }

    .mdc-text-3-5-3 {
        font-size: 25px;
        line-height: 30px!important;
    }

    .mdc-text-3-5-2 {
        font-size: 1.875rem;
    }

    .mdc-text-3 {
        font-size: 1.5625rem;
    }

    .mdc-text-3-5-4 {
        font-size: 1.4625rem;
    }

    .mdc-text-3-5 {
        font-size: 1.125rem;
    }

    .mdc-text-4 {
        font-size: 0.9375rem !important;
    }

    .mdc-text-4-1 {
        font-size: 1.08rem !important;
    }

    .mdc-text-5 {
        font-size: 0.75rem;
    }
    .mdc-text-6 {
        font-size: 0.65rem;
    }

    .mdc-fs-default {
        font-size: 1rem;
    }


    .mdc-fs-0 {
        font-size: 0.75rem;
    }

    .mdc-fs-1 {
        font-size: 0.75rem;
    }

    .mdc-fs-2 {
        font-size: 0.9375rem;
    }

    .mdc-fs-3,
    .mdc-fs-deuda,
    .mdc-fs-plan {
        font-size: 1.125rem;
    }

    .mdc-fs-4 {
        font-size: 1.375rem;
    }

    .mdc-fs-5 {
        font-size: 1.5625rem;
    }

    .mdc-fs-6 {
        font-size: 1.75rem;
    }

    .mdc-fs-7 {
        font-size: 1.8125rem;
    }

    .mdc-fs-8 {
        font-size: 2.1875rem;
    }

    .mdc-fs-8-1 {
        font-size: 3.125rem;
    }

    .mdc-fs-9 {
        font-size: 3.4375rem;
    }

    .mdc-fs-10 {
        font-size: 3.75rem;
    }

    .mdc-fs-11 {
        font-size: 4.0625rem;
    }

    .mdc-fs-12 {
        font-size: 4.375rem;
    }

    .mdc-fs-13 {
        font-size: 1.8rem;
    }

    .mdc-sesion-title {
        font-size: 2.5rem;
        font-family: $din;
    }

    .mdc-title-des {
        font-size: 1rem;
    }

    @media (max-width: 1200px) {
        .display-3 {
            font-size: 3.5rem;
        }

        .mdc-text-1 {
            font-size: 3.0rem;
        }
    }

    @media (max-width: 991.98px) {

        .mdc-sesion-title {
            font-size: 30px;
            font-family: $din;
        }

        .mdc-text-3 {
            font-size: 1.1rem;
        }

        .mdc-text-3-5-1 {
            font-size: 1.6rem;
        }
        .mdc-fs-3,
        .mdc-fs-plan {
            font-size: 16px;
        }

        .mdc-text-2 {
            font-size: 35px;
        }

        .mdc-fs-6 {
            font-size: 20px;
        }

        .mdc-fs-9 {
            font-size: 40px;
        }

        .mdc-fs-9-1 {
            font-size: 45px;
        }

        .mdc-fs-10 {
            font-size: 2rem;
        }

        .display-3 {
            font-size: 4.3rem;
        }

        .mdc-fs-8 {
            font-size: 20px;
        }
    }

    /**** fin de text-sizes ****/
    @media (max-width: 576px) {
        .mdc-text-3 {
            font-size: 20px;
        }
        
        .mdc-fs-3,
        .mdc-fs-plan {
            font-size: 16px;
        }

        .mdc-fs-13 {
            font-size: 2rem;
        }

        /*** font sizes ****/
        .mdc-fs-small {
            font-size: 14px;
        }

        .mdc-text-1 {
            font-size: 30px;
        }

        .mdc-text-2 {
            font-size: 25px;
        }

        .mdc-text-2-5 {
            font-size: 30px;
        }

        .mdc-text-3 {
            font-size: 20px;
        }

        .mdc-fs-2 {
            font-size: 0.8rem;
        }

        .mdc-fs-3 {
            font-size: 16px;
        }

        .mdc-fs-4 {
            font-size: 1rem;
        }

        .mdc-fs-13 {
            font-size: 1.2rem;
        }

        .mdc-fs-9 {
            font-size: 35px;
        }

        .mdc-fs-12 {
            font-size: 33px;
        }

        .media-text-22 {
            font-size: 22px;
        }

        .mdc-text-3-5-1 {
            font-size: 20px;
        }

        .mdc-text-3-5-2 {
            font-size: 22px;
        }

        .mdc-sesion-title {
            font-size: 30px;
            font-family: $din;
        }

        .mdc-title {
            font-size: 18px;
            line-height: 22px!important;
        }

        .display-3 {
            font-size: 3rem;
        }

        .mdc-fs-deuda {
            font-size: 14px;
            padding-left: 15px;
        }
    }

    @media (max-width: 425px) {
        .mdc-title-des {
            font-size: 0.875rem;
        }

        .mdc-fs-5 {
            font-size: 22px;
        }

        .display-3 {
            font-size: 1.9rem;
        }

        .mdc-text-2 {
            font-size: 20px;
        }

        .mdc-text-3 {
            font-size: 16px;
        }
    }
    /* new add headers */
    h5{
        font-family: $din;
    }
    h2 {
        font-family: $din;
    }
}