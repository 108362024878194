body {
    .mdc-tb-history thead tr {
        background: rgba(118, 117, 121, 0.15);
    }

    .mdc-tb-history thead tr th {
        text-align: center;
        font-size: 14px;
        padding: 0.5em 16px;
        vertical-align: middle;
    }

    .mdc-tb-history-lv-2 thead tr th {
        background: rgba(255, 255, 255, 0.5);
    }

    .mdc-tb-history thead tr th img {
        margin-top: -5px;
        height: 18px;
    }

    .mdc-tb-history tbody {
        background: rgba(255,255,255,0.5);
    }

    .mdc-tb-history tbody tr {
        background: rgba(255,255,255,0.5);
    }

    .mdc-tb-history tbody tr td {
        border: none;
        text-align: center;
        color: $gray-primary;
        font-size: 13px;
        padding: 0 8px 16px;
    }
    .mdc-tb-history tbody.tfoot tr td {
        border: none;
        padding: 0.5rem 0.3rem;
        text-align: center;
        color: inherit;
        font-size: 16px;
        font-weight: 700;
    }
    .b-right {
        border-right: 1px solid $gray-light;
    }

    td.cell-default {
        background: $aldia !important;
    }
    
    td.cell-unrated {
        background: $default !important;
        height: 10px;
    }
    
    /** nav de politicas de privacidad ***/
    .mdc-navs-politica {
        background: #2d1e4c;
        height: auto;

        @media (min-width: 768px) {
            
            min-height: 876px;
        }
    }

    .mdc-navs-politica li {
        padding-right: 1.3rem;
        padding-left: 1.3rem;
    }

    .mdc-navs-politica li a {
        text-align: center;
        color: #fff !important;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
        padding-top: 25px;
        padding-bottom: 25px;
        font-weight: bold;
        font-size: 14px;
    }

    .mdc-navs-politica li a:hover,
    .mdc-navs-politica li a:focus {
        border: none;
    }

    .mdc-navs-politica li.active {
        background-color: #632678 !important;
    }

    .mdc-navs-politica li a.active {
        background-color: transparent !important;
        border: none;
    }

    ol.mdc-list-letters {
        list-style-type: lower-alpha;
        padding-left: 20px;
    }

    ol.mdc-list-letters li {
        padding-left: 30px;
        color: #6f6f6e;
    }

    ul.lateral .tab {
        background: #f6f6f6;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        padding: 0 15px 0 25px;
        border-bottom: solid 2px #fff;
        height: 100px;
        margin: auto 0 auto 0;
    }

    ul.lateral .tab:hover {
        border-left: solid 2px #632678;
        background: #fff;
    }

    ul.lateral .tab a {
        width: 100%;
        display: inline-block;
        cursor: pointer;
        color: #632678;
    }

    /**** fin de navs ******/
}
