body {
    /***** modals *****/
    .mdc-modal-dialog {
        max-width: 95%;
        width: 600px;
        border-radius: 15px;
    }
    .mdc-close {
        position: absolute;
        top: -10px;
        right: -7%;
    }

    button.close > span {
        font-size: 40px;
        color: $white;
        font-weight: 100;
        height: 50px;
        width: 50px;
    }

    @media (max-width: 767.98px) {
        /** modals **/
        .mdc-close {
            right: 0;
            font-size: 40px;
        }
    }
}