/* Utilities */
@import './assets/scss/abstracts/variables';  /* Variables */
@import './assets/scss/base/fonts'; /* Fonts */
@import './assets/scss/vendors/bootstrap';
@import './assets/scss/base/reset'; /* Reset /normalize */
@import './assets/scss/base/typography'; /* Typography rules */
@import './assets/scss/layout/forms';   /* Forms */
@import './assets/scss/components/pago'; /* Habito de pago */
@import './assets/scss/components/tables'; /* Tables */
@import './assets/scss/components/tabs'; /* Tabs */
@import './assets/scss/components/buttons'; /* Buttons */
@import './assets/scss/components/icons'; /* Icons */
@import './assets/scss/components/cards'; /* Cards */
@import './assets/scss/components/borders'; /* Borders */
@import './assets/scss/components/modals'; /* Modals */
@import './assets/scss/components/blocks'; /* Blocks */
@import './assets/scss/components/progressbar'; /* Progress Bar */
@import './assets/scss/components/chatbot'; /* Chatbot */
@import './assets/scss/components/plans'; /* Plans */
@import './assets/scss/themes/global'; /* Global Theme */

html, body { 
      height: 100%; 
}
body { 
      margin: 0; 
      font-family: Roboto, "Helvetica Neue", sans-serif; 
}
.bg-menu {
      background-color: #3b2b5b !important;
      @media(max-width:768px){
            display: none;
      }
}
.back {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: #6851AF!important;
      margin-bottom: 16px;
      cursor: pointer;
      &:hover {
            color: #492F77;
            text-decoration: none;
      }
      i {
            margin-right: 16px;
      }
}
/* Spinner Loader */
.spinGral {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 12px auto 0;
      width: 100%;
      .spinner-gif {
            width: 42px;
            margin-right: 10px;
      }
      .textSpin {
            font-family: $roboto;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #632678;
            span {
                  font-weight: 600;
            }
            &.error {
                  color: #F24343;
            }
      }
}
.form-control{
      &.is-valid{
            padding-right: calc(0.5em + 0.75rem) !important;
      }
      &.is-invalid{
            padding-right: calc(0.5em + 0.75rem) !important;
      }
}
.searchC {
      border: none;
      width: 100%;
      display: block;
      position: absolute;
      top: 0;
      height: 42px;
      z-index: 1;
      padding-left: 20px;
}

.custom-loader {
      width: 48px;
      height: 48px;
      border: 5px solid #FFF;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
}
  
@keyframes rotation {
      0% {
          transform: rotate(0deg);
      }
      100% {
          transform: rotate(360deg);
      }
} 