$primary-color: #632678;
$secondary-color: #3b2b5b;
$alternative-color: #F9B550;
$white: #FFFFFF;
$dark-white: #f1f1f7;
$black: #000000;
$third-color: #dadae1;
$gray-dark: #424448;
$gray-primary: #575756;
$gray-secondary: #c6c6c6;
$gray-disabled: #999;
$gray-link: #6F6F6E;
$gray-light: #e3e3e3;
$gray-light-2: #A0A0A0;
$gray-light-3: #EDEDED;
$gray-light-4: #F8F8F8;
$gray-light-5: #AFAFAF;
$gray-light-6: #B3B3B3;
$gray-light-7: #EAEAEA;
$gray-light-8: #B4B4B4;
$gray-light-9: #CECECE;
$gray-3: #808080;
$gray-4: #474749;
$gray-5: #707070;
$gray-6: #676767;
$gray-7: #545454;
$gray-8:#6F6F6F;
$gray-9: #666666;
$gray-10: #848484;
$pink: #ba2f7d;
$pink-bright: #db1073;
$error:#F24343;
$check: #00CC99;
$purple-dark: #443462;
$purple-dark-2:#492F77;
$buscacredito-color: #26478d;
$bg: rgba(241,241,247,0.2);
$blue-light:#bee2ff;
$blue-dark:#00508f;

/* Habito de pago */
$aldia: #99D2B0;
$castigo: #632678;
$dudoso: #D66691;
$mora1: #CDD151;
$mora2: #F9B550;
$mora3: #C14F9D;
$default: #e4e4e4;
$alert: #9F79FF;
$txt-alert:#6338A1;

/*  Diagnostico */
$color-diagnostico: #54565A;
$regular: #E499B6;
$good: #FEF5A7;
$excellent: #99D2B0;
$unrated: #D2D2D2;

/*  Escaneo  */
$back-escaneo: #55307E;
$r-escaneo: #65428A;
$surface-web: #632678;
$deep-web: #3F3A84;
$dark-web: #3B2B5B;

/* NUEVOS COLORES */
$gray-txt-dark: #666666;
$gray-txt-light: #939393;
$gray-txt-light-2: #D3D3D3;
$gray-txt-super-light:#f4f4f9;
$contact: #9F79FF;
$purple-dark-3: #4E1375;
$warnning-blue: #50b2ff;
$red-ribbon: #F81919;

/* Mi deuda */
$balance1: #3D87AF;
$balance2: #3B2B5B;
$balance3: #BA2F7D;
$balance4: #00A29A;

/* table */
$head: #D8D5DE;

/* box alerts */
$blue-light2:#50b2ff;