
/******* buttons **********/
body {
    .mdc-btn-bc,
    .mdc-btn-bc:disabled {
        background: $buscacredito-color;
        color: $white;
        border-radius: 5px;
        font-size: 1.25rem;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
        box-shadow: rgba(109,107, 38, 0.2) 2px 2px 2px;
    }

    .mdc-btn-bc:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-bc:hover {
        color: $white;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }
    
    .mdc-btn-pink,
    .mdc-btn-pink:disabled {
        background: $pink;
        color: $white;
        border-radius: 5px;
        font-size: 1.25rem;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
        box-shadow: rgba(109,107, 38, 0.2) 2px 2px 2px;
    }

    .mdc-btn-pink:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-pink:hover {
        color: $white;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }

    .mdc-btn-outline,
    .mdc-btn-outline:disabled {
        background: transparent;
        color: $white;
        border-radius: 30px;
        font-size: 1.25rem;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
        border: 1px solid $white;
    }

    .mdc-btn-outline:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-outline:hover {
        color: $white;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }

    .mdc-btn-primary-outline,
    .mdc-btn-primary-outline:disabled {
        background: transparent;
        color: $primary-color;
        border-radius: 30px;
        font-size: 1.25rem;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
        border: 1px solid $primary-color;
    }

    .mdc-btn-primary-outline:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-primary-outline:hover {
        color: $primary-color;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }

    .mdc-btn-alter,
    .mdc-btn-alter:disabled {
        background: $alternative-color;
        color: $white;
        font-size: 1.25rem;
        border-radius: 30px;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
    }

    .mdc-btn-alter:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-alter:hover {
        color: $white;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }

    .midc-modal-btn-violet {
        background: $pink;
        color: $white;
    }

    .mdc-btn-primary,
    .mdc-btn-primary:disabled {
        background: $primary-color;
        color: $white !important;
        border-radius: 5px;
        font-size: 1.25rem;
        opacity: 1 !important;
        max-width: 337px;
        max-height: 65px;
        box-shadow: rgba(109,107, 38, 0.2) 2px 2px 2px;
        border: 1px solid $primary-color;
    }

    .mdc-btn-primary:disabled > .mt-arrow {
        cursor: not-allowed;
        pointer-events: none;
    }

    .mdc-btn-primary:hover {
        color: $white;
        border: 1px solid $primary-color;
        background: $primary-color;
        text-decoration: none !important;
        text-decoration-color: transparent;
        opacity: 0.8 !important;
    }

    .mdc-btn-secondary:hover {
        color: $white;
        text-decoration: underline;
        text-decoration-color: $gray-primary;
    }

    .mdc-btn-primary:active,
    .mdc-btn-secondary:active {
        background-color: $primary-color;
        color: $white;
        text-decoration: underline;
        text-decoration-color: $white;
    }

    .mdc-btn-secondary {
        background: $primary-color;
        color: $white;
        border-radius: 0px;
        font-size: 1.15rem;
        -webkit-box-shadow: -4px 3px 12px -8px rgba(0,0,0,0.75);
        -moz-box-shadow: -4px 3px 12px -8px rgba(0,0,0,0.75);
        box-shadow: -4px 3px 12px -8px rgba(0,0,0,0.75);
    }

    .midc_cta_icon {
        width: 8px;
        margin: 0;
        margin-left: 5px;
        margin-top: -3px;
    }

    .mdc-btn-rd {
        border-radius: 10px;
    }

    .midc_cta_icon_aux {
        position: absolute;
        top: 45%;
        left: 80%;
        width: 8px;
        margin: 0;
        margin-left: 5px;
        margin-top: -3px;
    }

    /**** fin de buttons ***/

    @media (max-width: 991.98px) {
        .btn {
            font-size: 0.9rem !important;
        }

        .mdc-btn-rd {
            border-radius: 10px;
        }

        .mdc-btn-secondary {
            font-size: 14px;
            padding-left: 5px;
            padding-right: 5px;
        }
    }

    @media (min-width: 768px) and (max-width: 991.98px) {
        .card .btn {
            font-size: 0.6rem !important;
        }
    }

    @media (max-width: 576px) {
        .mdc-btn-rd {
            border-radius: 10px;
        }

        .btn {
            padding: 10px 15px !important;
            font-size: 15px !important;
        }
    }
}

.btn-midc {
  display: flex;  
  padding: 10px 0;
  border-radius: 8px;
  text-align: center;  
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  text-decoration:none;
  @media(max-width:767px){
      margin: 8px auto 8px;
  }
  &.margin-l {
    margin: 16px 0 24px;
    @media(max-width:767px){
      margin: 16px auto 24px;
    }
  }
  &.margin-r {
    margin: 16px 0 24px;
    @media(max-width:767px){
      margin: 16px auto 24px;
    }
  }
  &.margin-auto {
    margin: 16px auto 24px;
    @media(max-width:767px){

    }
  }
  &.h-lg-btn {
    height: 52px;
    @media(max-width:767px){
      height: 46px;
    }
  }
  &.h-md-btn {
    height: 46px;
    @media(max-width:767px){
      height: 42px;
    }
  }
  &.h-xs-btn {
    height: 42px;
    @media(max-width:767px){
      height: 42px;
    }
  }
  &.w-40-btn {
    width: 40%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-45-btn {
    width: 45%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-50-btn {
    width: 50%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-60-btn {
    width: 60%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-70-btn {
    width: 70%;
    @media(max-width:767px){
      width: 100%;
    }
  }
  &.w-100-btn {
    width: 100%;
  }
  &.btn-orange{
    background-color: $alternative-color;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 600;
    color: $primary-color;
    position: relative;
    &:hover {
      color:$primary-color;
      background-color: rgba($color: $alternative-color, $alpha: .8);
      text-decoration: none;
    }    
    i {            
        font-size: 13px;
        position: absolute;
        right: 18px;
    }
  }
  &.btn-orange2{
    background-color: $alternative-color;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 600;
    color: $primary-color;
    position: relative;    
    i {            
        font-size: 13px;
        position: absolute;
        right: 18px;
    }
  } 
  &.btn-purple{
    background-color: $primary-color;
    color:$white;
    &:hover {
      color:$white;
      background-color: $secondary-color;
      text-decoration: none;
    }
    span {
        font-family: $roboto;
        font-size: 15px;
        display: inline-block;
        margin: 0 8px;
        @media(max-width:767px){
          font-size: 16px;
        }
    }
    i {            
        font-size: 13px;
        color: $white;
        display: inline-block;
    }
  }
  &.btn-outline-purple{
    background-color: transparent;
    color:$txt-alert;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 600;
    position: relative;
    border: 1px $txt-alert solid;
    &:hover {
      background-color: rgba($color: $txt-alert, $alpha: .15);
      text-decoration: none;
    }    
    i {            
        font-size: 13px;
        position: absolute;
        right: 18px;
    }
  }
  &.btn-outline-white{
    border: 1px #fff solid;
    color:$white;   
    background-color: transparent; 
    &:hover {
      background-color: rgba($color: $white, $alpha: .2);
      color: $white;
    }
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 12 px;
      display: inline-block;
    }
  }
  &.btn-outline-pink{
    background-color: $white;
    &:hover {
      background-color: rgba($color: $white, $alpha: .2);
      color: $primary-color;
    }
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
  &.btn-link{
    span {
      font-family: $roboto;
      font-size: 15px;
      display: inline-block;
      margin: 0 8px;
    }
    i {            
      font-size: 13px;
      display: inline-block;
    }
  }
  &:disabled{
    background-color: $gray-light-5;
    cursor: default;
    color: $gray-light-2;
  }
  &.btn-disabled{
    background-color: #ccc;
    cursor: default;
    &:hover{
      background-color: #ccc;
      cursor: default;
      color: $white;
    }
  }
}