.mdc-tb-calendar p {
    color: $white;
    font-weight: 600;
    margin: 0 auto;
}
.bg-n {
    background: $aldia;
}
.bg-c {
    background: $castigo;
}
.bg-d {
    background: $dudoso;
}
.bg-30 {
    background: $mora1;
}
.bg-60 {
    background: $mora2;
}
.bg-90 {
    background: $mora3;
}
.bg-default {
    background: $default;
}